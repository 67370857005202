import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

    this.setState({ error, errorInfo });

    console.error(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const Component = this.props.render || this.props.fallback;

      return Component ? (
        <Component
          error={this.state.error}
          errorInfo={this.state.errorInfo}
          clearError={() => this.setState({ hasError: false, error: null, errorInfo: null })}
        />
      ) : null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
