import {Transition} from '@headlessui/react';
import {usePage} from '@inertiajs/react';
import {mdiArrowDown, mdiChevronUp, mdiClose, mdiConsole} from '@mdi/js';
import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import cls from 'Support/cls'
import React, {useEffect, useMemo, useRef, useState} from 'react';
import useDevMode from 'Support/Hooks/useDevMode';
import useUser from 'Support/Hooks/useUser';

const BRANCH_CLASSES = [
  'border-blue-100',
  'border-green-100',
  'border-yellow-100',
  'border-red-100',
  'border-purple-100',
  'border-pink-100',
]

const objToTree = (obj, depth=0) => {
  const children = [];
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key];
      const subChildren = [];
      if (typeof value === 'object') {
        if ([null, undefined].includes(value)) {
          subChildren.push(<span className="text-orange-500">{value === null ? 'null' : 'undefined'}</span>);
        }
        else if (Array.isArray(value)) {
          subChildren.push(<><span className="text-blue-500">Array</span><span className="text-gray-500">[{value.length}] </span></>);
          value?.length && subChildren.push(<><span className="text-gray-400">[</span>{objToTree(value, depth+1)}<span className="text-gray-400">]</span></>);
        } else if (Object.keys(value).length === 0) {
          subChildren.push(<span className="text-orange-500">&#123;&#125;</span>);
        } else {
          subChildren.push(<><span className="text-gray-400">&#123;</span>{objToTree(value, depth+1)}<span className="text-gray-400">&#125;</span></>);
        }
      } else if (typeof value === 'function') {
        subChildren.push(<span className="text-purple-500">Function</span>);
      } else if (typeof value === 'boolean') {
        subChildren.push(<span className="text-red-500">{value ? 'true' : 'false'}</span>);
      } else {
        subChildren.push(<span>{value}</span>);
      }
      children.push(<li>
        <Tippy content="Print value to console" delay={[1000, 0]}>
          <button type="button" onClick={() => console.log(value)} className="text-green-500 hover:bg-gray-100 rounded">
            {key}
          </button>
        </Tippy>
        <span>: </span>{subChildren}
      </li>);
    }
  }
  return <ul className={cls('ml-1 pl-4 border-l', BRANCH_CLASSES[depth % BRANCH_CLASSES?.length])}>{children}</ul>;
};

function filterObject(obj, search) {
  let filteredObj = {};
  let found = false;

  for (let key in obj) {
    if (key.includes(search)) {
      filteredObj[key] = obj[key];
      found = true;
    } else if (typeof obj[key] === "object") {
      let filtered = filterObject(obj[key], search);
      if (Object.keys(filtered).length > 0) {
        filteredObj[key] = filtered;
        found = true;
      }
    }
  }

  if (!found) {
    filteredObj = {};
  }

  return filteredObj;
}

const JSONSection = ({title, data}) => {
  const [search, setSearch] = useState('');
  const row = useRef();

  const tree = useMemo(() => (
    objToTree(filterObject(data, search))
  ), [data, search]);

  return (
    <tr ref={row} className="group/row divide-x h-0">
      <td className="h-inherit w-0 pr-5">
        <div className="h-full flex flex-col gap-1 p-2">
          <div className="sticky top-2 flex flex-col gap-1">
            <div className="text-sm font-medium text-gray-500">{title}</div>
            <button type="button" onClick={() => {console.log(data);}} className="self-start p-1 rounded text-gray-600 bg-gray-200 hover:opacity-80">
              <Icon path={mdiConsole} className="w-4"/>
            </button>
          </div>
          <div className="grow flex items-end group-last/row:hidden">
            <button type="button" onClick={() => {
              row.current?.nextSibling.scrollIntoView({behavior: 'smooth', block: 'start'});
            }}
                    className="sticky bottom-2 flex items-center gap-2 p-1 text-gray-500 text-opacity-0 hover:text-opacity-100 duration-100 hover:bg-gray-200 rounded whitespace-nowrap">
              <Icon path={mdiArrowDown} className="w-4 text-gray-500"/>
              <div className="text-sm">Next Section</div>
            </button>
          </div>
        </div>
      </td>
      <td className="p-0">
        <div className="flex flex-col">
          <div className="flex border-b text-sm">
            <div className="flex items-center px-2 bg-gray-100">Filter Keys:</div>
            <input type="search" onChange={({target: {value}}) => setSearch(value)} className="grow p-1 border-0 border-l border-gray-200"/>
          </div>
          <div className="p-2 text-sm">
            {tree}
          </div>
        </div>
      </td>
    </tr>
  );
};

const DevModeOverlay = () => {
  const [{show, expanded}, setViewState] = useState({show: false, expanded: false});
  const keyHeld = useRef(false);
  const timeout = useRef();
  const [isDev] = useDevMode();

  useEffect(() => {
    if (isDev) {
      const handleKeyDown = (event) => {
        if (event.key?.toLowerCase() === 'd') {
          keyHeld.current = true;
          timeout.current = setTimeout(() => {
            if (keyHeld.current) {
              keyHeld.current = false;
              setViewState({show: true, expanded: false});
            }
          }, 2000);
        }
      };

      const handleKeyUp = (event) => {
        if (event.key?.toLowerCase() === 'd') {
          keyHeld.current = false;
          clearTimeout(timeout.current);
          timeout.current = null;
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [isDev]);

  return (
    <Transition
      as={React.Fragment}
      show={isDev && show}
      enter="duration-300"
      enterFrom="opacity-0 translate-y-full"
      enterTo="opacity-100"
      leave="duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0 translate-y-full"
    >
      <div className="fixed inset-x-0 bottom-0 z-[100] flex flex-col rounded overflow-hidden bg-white border shadow-lg duration-500 ease-in-out max-h-64"
           style={{
             margin: '2rem',
             ...expanded && {maxHeight: 'calc(100vh - 4rem)'},
           }}>
        <div className="flex items-center gap-5 justify-between p-2 font-medium border-b border-orange-500 shadow">
          <div className="flex items-center gap-2">
            <button type="button" onClick={() => setViewState({show: false, expanded: false})}
                    className="p-1 rounded duration-100 text-white bg-orange-500 rounded-full hover:bg-orange-600">
              <Icon path={mdiClose} className="w-4"/>
            </button>
            <div>Developer Overlay</div>
          </div>
          <button type="button" onClick={() => setViewState(prevState => ({...prevState, expanded: !prevState?.expanded}))}
                  className="hover:bg-gray-200 duration-100 rounded">
            <Icon path={mdiChevronUp} className={cls('w-6 duration-300', expanded && 'rotate-180')}/>
          </button>
        </div>
        <div className="grow overflow-y-auto">
          <table className="w-full">
            <tbody className="divide-y">
            <JSONSection title="Page Props" data={usePage()}/>
            <JSONSection title="User" data={useUser()}/>
            </tbody>
          </table>
        </div>
      </div>
    </Transition>
  );
};

export default DevModeOverlay;