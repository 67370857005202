import { createInertiaApp } from '@inertiajs/react';
import { FormErrorContextProvider, InitialApplicationContext } from '@webfox/webfox-ui';
import * as React from 'react';
import { JSXElementConstructor, StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import 'Style/app.css';
import DevModeListener from './Components/DevModeListener';
import DevModeOverlay from './Components/DevModeOverlay';
import MainLayout from './Layouts/MainLayout';
import { toast, ToastContainer } from 'react-toastify';
import { Icon } from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import PrintWrapper from './Components/PrintWrapper';
import PrintLayout from './Layouts/PrintLayout/PrintLayout';
import axios from 'axios';

window.addEventListener('unhandledrejection', (event) => {
  if (axios.isCancel(event?.reason)) {
    event.preventDefault();
    return;
  }
  console.error('Unhandled promise rejection:', event?.reason);
});

createInertiaApp<Window['inertiaInitialPageData']['props']>({
  resolve: async (name) => {
    const comps = import.meta.glob<{
      default: JSXElementConstructor<any>;
    }>('./Pages/**/*.{tsx,jsx}');

    const page = (await (comps[`./Pages/${name}.tsx`] ?? comps[`./Pages/${name}.jsx`])()).default;
    page.layout = page?.layout ?? MainLayout;
    return page;
  },
  title: (title) => 'HX' + (title ? ` | ${title}` : ''),
  page: window.inertiaInitialPageData,
  setup({ el, App, props }) {
    createRoot(el).render(
      <StrictMode>
        <InitialApplicationContext.Provider value={{ application: props.initialPage.props.application }}>
          <App {...props}>
            {({ key, props, Component }) => {
              useEffect(() => {
                if (Array.isArray(props.flash)) {
                  props.flash.forEach(({ id, type, message, ...options }) => {
                    if (id) {
                      toast.update(id, {
                        autoClose: 5000,
                        delay: 20, // need to specify a delay here to avoid a bug https://github.com/fkhadra/react-toastify/issues/918
                        isLoading: false,
                        type,
                        icon: <Icon path={mdiCheckCircle} />,
                        render: message,
                        closeOnClick: true,
                        ...options,
                      });
                    } else {
                      toast(message, { type, ...options });
                    }
                  });
                }
              }, [JSON.stringify(props.flash)]);

              const children = <Component {...props} key={key} />;
              const page = Component.layout(children, Component?.subLayout);

              return (
                <FormErrorContextProvider>
                  <DevModeListener>
                    <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover theme="colored" position="bottom-right" />
                    <PrintWrapper>{(isPrinting) => (isPrinting ? <PrintLayout children={children} /> : page)}</PrintWrapper>
                    <DevModeOverlay />
                  </DevModeListener>
                </FormErrorContextProvider>
              );
            }}
          </App>
        </InitialApplicationContext.Provider>
      </StrictMode>,
    );
  },
});
