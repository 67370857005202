import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';

const useIsPrinting = () => {
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    const onBeforePrint = () => {
      flushSync(() => setIsPrinting(true));
    };

    const onAfterPrint = () => {
      flushSync(() => setIsPrinting(false));
    };

    window.addEventListener('beforeprint', onBeforePrint);
    window.addEventListener('beforeprintspecial', onBeforePrint);
    window.addEventListener('afterprint', onAfterPrint);
    window.addEventListener('afterprintspecial', onAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', onBeforePrint);
      window.removeEventListener('beforeprintspecial', onBeforePrint);
      window.removeEventListener('afterprint', onAfterPrint);
      window.removeEventListener('afterprintspecial', onAfterPrint);
    };
  }, []);

  return isPrinting;
};

export default useIsPrinting;
