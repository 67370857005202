import { Link, usePage } from '@inertiajs/react';
import { mdiArrowLeft, mdiChevronDown } from '@mdi/js';
import { Icon } from '@mdi/react';
import AccountMenu from 'Components/AccountMenu';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import React, { forwardRef, useMemo, useState } from 'react';
import cls from 'Support/cls';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';
import { accountMenuItems } from '../../MainLayoutConfig';

const isActive = (...names) => names.some((name) => route().current(name, []));

const LeftNavItem = forwardRef(({ depth, groupId, id, item, collapsed }, ref) => {
  const Element = item.href ? Link : 'button';
  const rootProps = item.href ? { href: item.href } : { type: 'button' };
  const [expanded, setExpanded] = useState(item.active);
  const icon = typeof item.icon === 'string' ? <Icon path={item.icon} /> : item.icon;

  return (
    <div ref={ref} className={cls('relative flex flex-col text-white', !!item?.items?.length ? 'my-1' : 'py-1')}>
      <Element
        {...rootProps}
        onClick={() => item.items?.length && setExpanded(!expanded)}
        className={cls(
          'flex items-center gap-5 px-5 outline-none duration-300 focus:outline-none  focus-visible:bg-white/30',
          !item.active && 'opacity-50 hover:opacity-75',
        )}
      >
        <div className="box-content h-7 w-7 shrink-0 p-1.5">
          {icon || <Icon path={mdiChevronDown} className={cls('duration-300', expanded && '-rotate-180')} />}
        </div>
        <div className={cls('truncate font-medium duration-300', collapsed ? 'opacity-0' : 'opacity-100')}>{item?.title}</div>
      </Element>
      {!!item?.items?.length && (
        <LayoutGroup id={`nav-group-${id}`}>
          <motion.div
            layoutRoot
            initial="collapsed"
            variants={{
              expanded: { height: 'auto', opacity: 1 },
              collapsed: { height: 0, opacity: 0 },
            }}
            animate={expanded ? 'expanded' : 'collapsed'}
            className="overflow-hidden"
          >
            <div className="relative flex flex-col gap-5 pt-5">
              {item.items.map((item, index) => (
                <LeftNavItem depth={depth + 1} groupId={`nav-group-${id}`} id={`${id}/${index}`} key={index} item={item} collapsed={collapsed} />
              ))}
            </div>
          </motion.div>
        </LayoutGroup>
      )}
      <AnimatePresence>
        {item.active && (
          <motion.div
            layout
            key={`nav-highlight-${groupId}`}
            layoutId="nav-highlight-bar"
            className="absolute inset-y-0 left-0 bg-primary"
            initial={{ width: 0 }}
            animate={{ width: 4 * depth }}
            exit={{ width: 0 }}
          />
        )}
      </AnimatePresence>
    </div>
  );
});

const buildNavTree = (items, pageData) => {
  return items.map((item) => {
    const items = item?.items ? buildNavTree(item.items, pageData) : [];

    return {
      ...item,
      items,
      active:
        (item?.active && (typeof item.active === 'function' ? item.active(pageData) : isActive(item.active))) || items.some((item) => item.active),
    };
  });
};

const LeftNav = ({ items: propItems, largeLogoSrc, smallLogoSrc, collapsed, onChangeCollapsed }) => {
  const user = useUser();
  const pageData = usePage();

  const items = useMemo(() => buildNavTree(propItems, pageData), [propItems, pageData]);

  return (
    <nav className={cls('flex shrink-0 flex-col gap-5 transition-all duration-300 ease-in-out', collapsed ? 'w-20' : 'w-60')}>
      <Link href={route('dashboard')} className={cls('flex h-24 items-center justify-center duration-300', collapsed ? 'px-3' : 'px-10')}>
        <img src={collapsed ? smallLogoSrc : largeLogoSrc} alt="Logo" className="object-contain" />
      </Link>
      <LayoutGroup id="nav-root">
        <motion.div layoutScroll className="overflow-y-overlay relative flex flex-col gap-5 overflow-x-hidden">
          {items.map((item, index) => (
            <LeftNavItem depth={1} groupId="nav-root" id={index} key={index} item={item} collapsed={collapsed} />
          ))}
        </motion.div>
      </LayoutGroup>
      <div className="mt-auto flex flex-col">
        <button
          type="button"
          onClick={() => onChangeCollapsed(!collapsed)}
          className={cls(
            'self-end rounded-l-full bg-secondary p-5 text-white outline-none duration-300 hover:bg-black/30 focus:outline-none focus-visible:bg-white/30',
            collapsed ? 'mr-1.5 rounded-r-full' : 'bg-black/20',
          )}
        >
          <Icon path={mdiArrowLeft} className={cls('w-7 transform duration-500', collapsed && 'rotate-180')} />
        </button>
        <div className="flex items-center gap-5 p-5">
          <AccountMenu accountMenuItems={accountMenuItems} />
          <div className={cls('min-w-0 truncate text-sm text-white duration-300', collapsed && 'opacity-0')}>{user?.name || user?.email}</div>
        </div>
      </div>
    </nav>
  );
};

export default LeftNav;
