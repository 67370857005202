import ErrorBoundary from 'Components/ErrorBoundary';
import LeftNav from 'Layouts/Partials/LeftNav';
import PageError from 'Layouts/Partials/PageError';
import React from 'react';
import { ExpandedCollapsibles, useSidebarState } from 'Support/Hooks/useSidebarState';
import useUser from 'Support/Hooks/useUser';
import { navItems } from '../MainLayoutConfig';
import MobileNav from './Partials/MobileNav';

const defaultSubLayout = (page) => page;

const Navigation = () => {
  const user = useUser();
  const [expanded, setExpanded] = useSidebarState();

  const items = navItems(user);

  return (
    <>
      <div className="hidden lg:contents">
        <LeftNav
          items={items}
          largeLogoSrc="/hr-logo-white-no-text.png"
          smallLogoSrc="/hr-logo-small-white.png"
          collapsed={expanded !== ExpandedCollapsibles.NAVIGATION}
          onChangeCollapsed={(val) => setExpanded(val ? ExpandedCollapsibles.NONE : ExpandedCollapsibles.NAVIGATION)}
        />
      </div>
      <div className="contents lg:hidden">
        <MobileNav items={items} />
      </div>
    </>
  );
};

const MainLayout = (page, subLayout) => {
  const sub = subLayout || defaultSubLayout;

  return (
    <div className="flex h-screen overflow-hidden bg-secondary">
      <Navigation />
      <main className="overflow-overlay relative grow overflow-x-hidden bg-white text-slate-800 lg:rounded-l-2xl lg:mb-0 mb-10">
        <ErrorBoundary render={PageError}>{sub(page)}</ErrorBoundary>
      </main>
    </div>
  );
};

export default MainLayout;
