import {usePage} from '@inertiajs/react';
import React, {useEffect, useRef} from 'react';
import useDevMode from 'Support/Hooks/useDevMode';

const DevModeListener = ({children}) => {
  const [_, toggleDevMode] = useDevMode();
  const keyPresses = useRef({
    count: 0,
    lastTimestamp: null,
  });
  const page = usePage();

  useEffect(() => {
    if (!page?.props?.application?.production) {
      const onKeyDown = (event) => {
        if (event.key?.toLowerCase() === 'd' && event.shiftKey) {

          if (keyPresses.current.lastTimestamp === null) {
            keyPresses.current.lastTimestamp = Date.now();
          }
          if (Date.now() - keyPresses.current.lastTimestamp > 500) {
            keyPresses.current.count = 0;
          }
          keyPresses.current.lastTimestamp = Date.now();
          keyPresses.current.count++;

          if (keyPresses.current.count === 5) {
            toggleDevMode();
            keyPresses.current.count = 0;
          }
        }
      };

      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [page, toggleDevMode]);

  return children;
};

export default DevModeListener;