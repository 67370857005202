import { mdiAccount, mdiAccountGroup, mdiFileDocument, mdiHome, mdiLogout, mdiRayVertex } from '@mdi/js';
import route from 'ziggy-js';

export const navItems = (user) =>
  [
    ...(user?.organization
      ? [
          {
            title: 'Dashboard',
            href: route('dashboard'),
            icon: mdiHome,
            showMobile: true,
            active: 'dashboard',
          },
          {
            title: 'Reviews',
            href: route('review-templates.index'),
            icon: mdiFileDocument,
            showMobile: true,
            active: 'review-templates.*',
            filter: () => ['admin', 'manager'].includes(user.role.value),
          },
          {
            title: 'Teams',
            href: route('teams.index'),
            icon: mdiAccountGroup,
            showMobile: true,
            active: 'teams.*',
          },
          {
            title: 'Users',
            href: route('users.index'),
            showMobile: true,
            icon: mdiAccount,
            active: 'users.*',
            filter: () => ['admin'].includes(user.role.value),
          },
          // {
          //   title: 'Reports',
          //   href: route('reports.index'),
          //   icon: mdiPoll,
          //   active: 'reports.*',
          //   filter: () => ['admin', 'manager'].includes(user.role.value),
          // },
          {
            title: 'Settings',
            items: [
              {
                title: 'Value Thresholds',
                href: route('thresholds.index'),
                icon: mdiRayVertex,
                active: 'thresholds.*',
              },
            ],
            filter: () => ['admin'].includes(user.role.value),
          },
        ]
      : [
          {
            title: 'Organizations',
            href: route('organizations.index'),
            icon: mdiAccountGroup,
            showMobile: true,
            active: 'organizations.*',
          },
        ]),
  ].filter((item) => typeof item.filter !== 'function' || item.filter());

export const accountMenuItems = [
  {
    title: 'Log out',
    href: route('logout'),
    icon: mdiLogout,
  },
];
