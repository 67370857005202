import { mdiAccount } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Formik, HorizontalFieldWrapper, Modal, TextField, useInertiaForm, PrimaryButton } from '@webfox/webfox-ui';
import { Form } from 'formik';
import React from 'react';
import useUser from 'Support/Hooks/useUser';
import * as Yup from 'yup';
import route from 'ziggy-js';

const MenuItem = ({ icon, as: Element = 'button', children, ...props }) => (
  <Element
    {...(Element === 'button' && { type: 'button' })}
    {...props}
    className="flex items-center gap-2 text-slate-700 px-4 py-2 hover:bg-slate-100 duration-100 rounded-xl"
  >
    <Icon path={icon} className="w-5" />
    {children}
  </Element>
);

const AccountForm = ({ onClose }) => {
  const user = useUser();

  const formikProps = useInertiaForm({
    defaultValues: {
      name: user?.name,
      email: user?.email,
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().min(8, 'Password must be at least 8 characters'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .when('password', {
          is: (val) => val && val.length > 0,
          then: (schema) => schema.required('Password confirmation is required'),
        }),
    }),
    submit: ({ put }) =>
      put(route('account.update'), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
          onClose();
        },
      }),
  });

  return (
    <Formik {...formikProps}>
      {({ submitDisabled }) => (
        <Form>
          <div className="flex">
            <div className="hidden lg:flex flex-col border-r border-slate-200 pb-5">
              <div className="h-14 -mb-10 bg-slate-200 px-5"></div>
              <img
                src={user?.avatar}
                alt={`${user?.name || user?.email}'s Avatar `}
                className="self-center w-20 h-20 rounded-full shrink-0 border-2 border-white mb-5"
              />
              <div className="flex flex-col px-5">
                <MenuItem icon={mdiAccount}>Details</MenuItem>
              </div>
            </div>
            <div className="grow flex flex-col gap-5 p-10 overflow-overlay">
              <h1 className="font-semibold text-2xl text-slate-800">My Account</h1>
              <div className="flex flex-col gap-5">
                <HorizontalFieldWrapper label="Name" name="name" noBorder>
                  <TextField />
                </HorizontalFieldWrapper>
                <HorizontalFieldWrapper label="Email" name="email" noBorder>
                  <TextField type="email" />
                </HorizontalFieldWrapper>
                <HorizontalFieldWrapper label="New Password" name="password">
                  <TextField type="password" />
                </HorizontalFieldWrapper>
                <HorizontalFieldWrapper label="Confirm Password" name="password_confirmation" noBorder>
                  <TextField type="password" />
                </HorizontalFieldWrapper>
              </div>
            </div>
          </div>
          <Modal.Actions withoutMargin>
            <button
              type="button"
              onClick={onClose}
              className="rounded-lg px-6 py-3 text-sm font-medium text-slate-700 duration-100 hover:bg-slate-200 hover:text-slate-800"
            >
              Cancel
            </button>
            <PrimaryButton type="submit" disabled={submitDisabled}>
              {' '}
              Save{' '}
            </PrimaryButton>
          </Modal.Actions>
        </Form>
      )}
    </Formik>
  );
};

const UserDetailsModal = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} size="5xl">
      <Modal.Content withoutPadding>
        <AccountForm onClose={onClose} />
      </Modal.Content>
    </Modal>
  );
};

export default UserDetailsModal;
