import { useLocalStorage } from 'usehooks-ts';

export const ExpandedCollapsibles = {
  NONE: 'none',
  NAVIGATION: 'navigation',
  SIDEBAR: 'sidebar',
} as const;

export type ExpandedCollapsiblesType = typeof ExpandedCollapsibles;

export type ExpandedCollapsibleKey = keyof ExpandedCollapsiblesType;

export const useSidebarState = () =>
  useLocalStorage<ExpandedCollapsiblesType[ExpandedCollapsibleKey]>('expanded-collapsibles', ExpandedCollapsibles.NONE);
