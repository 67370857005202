import {createContext, useContext} from 'react';

type PrintLayoutContextType = {
  isPrinting: boolean;
  setPrintHeader: (header: React.ReactNode) => void;
  setPrintFooter: (footer: React.ReactNode) => void;
  printHeader: React.ReactNode | null;
  printFooter: React.ReactNode | null;
}

export const PrintLayoutContext = createContext<PrintLayoutContextType>({
  isPrinting: false,
  setPrintHeader: () => {},
  setPrintFooter: () => {},
  printHeader: null,
  printFooter: null,
});

export const usePrintLayout = () => {
  return useContext(PrintLayoutContext);
};

export default usePrintLayout;