import {Menu} from '@headlessui/react';
import {Link} from '@inertiajs/react';
import {Icon} from '@mdi/react';
import cls from 'Support/cls'
import React from 'react';

const MenuItem = ({children, href, icon, as = Link, ...itemProps}) => (
  <div className="py-1">
    <Menu.Item {...itemProps}>
      {({active, disabled}) => {
        const ElementTag = disabled ? 'button' : as;

        const elementProps = {};
        if (typeof href !== 'undefined') {
          elementProps.href = href;
        }

        if (disabled) {
          delete elementProps.href;
          elementProps.disabled = true;
        }

        if (ElementTag === 'button') {
          elementProps.type = 'button';
        }

        return (
          <ElementTag
            {...elementProps}
            className={cls({
              'bg-gray-100 text-gray-900': active,
              'text-gray-900': !active && !disabled,
              'text-gray-700 cursor-default': disabled,
              'focus:outline-none focus:bg-gray-100 focus:text-gray-900': !disabled,
              'flex w-full px-4 py-2 text-sm leading-5 text-left space-x-3 items-center': true,
            })}
          >
            {icon && <Icon path={icon} size={.7} className="h-5 w-5 text-gray-700 shrink-0"/>}
            <span className="truncate">{children}</span>
          </ElementTag>
        );
      }}
    </Menu.Item>
  </div>
);

export default MenuItem;
